<template>
  <div class="trends">
    <div class="project wrap_box">
      <echart
        :config="echartConfigProject"
        :option="echartOptionProject"
        v-if="echartOptionProject"
      />
    </div>
    <div class="column">
      <div class="online wrap_box">
        <echart
          :config="echartConfigOnline"
          :option="echartOptionOnline"
          v-if="echartOptionOnline"
        />
      </div>
      <div class="sexAndAge wrap_box">
        <div class="sex">
          <echart
            :config="echartConfigSex"
            :option="echartOptionSex"
            v-if="echartOptionSex"
          />
        </div>
        <div class="age">
          <echart
            :config="echartConfigAge"
            :option="echartOptionAge"
            v-if="echartOptionAge"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import echart from '@/libs/components/echart/echart'
import json from './json'
export default {
  name: 'TrendsAA',
  components: {
    echart
  },
  data () {
    return {
      userInfo: {},
      echartDataProject: { label: [] },
      echartOptionProject: '',
      echartConfigProject: {
        id: 'trendsProject',
        setStyle: { width: '95%', height: '95%', margin: '1rem auto' }
      },

      echartDataOnline: { label: [] },
      echartOptionOnline: '',
      echartConfigOnline: {
        id: 'trendsOnline',
        setStyle: { width: '95%', height: '96%', margin: '1rem auto' }
      },

      echartDataSex: [],
      echartOptionSex: '',
      echartConfigSex: {
        id: 'trendsSex',
        setStyle: { width: '95%', height: '95%', margin: '1rem auto' }
      },

      echartDataAge: [],
      echartOptionAge: '',
      echartConfigAge: {
        id: 'trendsAge',
        setStyle: { width: '95%', height: '95%' }
      }
    }
  },
  async mounted () {
    await this.init()
    const px = window.innerWidth / 120 / 16
    if (this.echartDataProject.label.length > 0) {
      this.echartOptionProject = this.buildDouble(
        this.echartDataProject,
        json.trendsData.barLine.title,
        json.trendsData.barLine.color,
        json.trendsData.barLine.position,
        json.trendsData.barLine.data,
        json.trendsData.barLine.option
      )
    }

    if (this.echartDataOnline.label.length > 0) {
      this.echartOptionOnline = this.buildDouble(
        this.echartDataOnline,
        json.trendsData.line.title,
        json.trendsData.line.color,
        json.trendsData.line.position,
        json.trendsData.line.data,
        json.trendsData.line.option
      )
    }

    let echartDataSex = this.echartDataSex
    this.echartOptionSex = {
      title: {
        text: json.trendsData.pie.title,
        textStyle: {
          color: '#fff',
          fontSize: 25 * px
        }
      },
      label: {
        rich: {
          a: {
            color: '#fff',
            fontSize: 16 * px,
            lineHeight: 24 * px,
            align: 'center'
          }
        },
        formatter: json.trendsData.pie.formatter
      },
      color: ['#0263FF', '#FF7723', '#906bf6'],
      series: [
        {
          // type: 'pie',
          // radius: '80%',
          type: 'pie',
          radius: '50%',
          roseType: 'area',
          avoidLabelOverlap: false,
          left: '5%',
          right: '5%',
          // label: { position: 'inside' },
          // avoidLabelOverlap: false,
          data: echartDataSex
        }
      ],
      ...json.trendsData.pie.option
    }

    let echartDataAge = this.echartDataAge
    this.echartOptionAge = {
      label: {
        rich: {
          a: {
            color: '#fff',
            fontSize: 16 * px,
            lineHeight: 24 * px,
            align: 'center'
          }
        },
        formatter: '{a|{b}\n{d}%}'
      },
      color: [
        '#906bf6',
        '#54ade9',
        '#ea71c8',
        '#7dfbfd',
        '#6bf672',
        '#f1f66b',
        '#f6b66b',
        '#f66b6b'
      ],
      series: [
        {
          type: 'pie',
          radius: '50%',
          roseType: 'area',
          right: '5%',
          left: '5%',
          avoidLabelOverlap: true,
          data: echartDataAge
        }
      ],
      ...json.trendsData.pie.option
    }
  },
  async created () {
    window.Y = this
    // this.init()
  },
  methods: {
    buildDouble (data, title, color, grid, yAxis, option) {
      const px = window.innerWidth / 120 / 16
      return {
        title: {
          text: title,
          textStyle: {
            color: '#fff',
            fontSize: 25 * px
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: data.data.filter(item => item.name),
          textStyle: {
            color: '#fff',
            fontSize: 16 * px
          },
          selectedMode: false
        },
        grid: grid,
        yAxis: [
          {
            type: 'value',
            name: yAxis[0].name,
            position: 'left',
            nameTextStyle: {
              padding: [0, 0, 20, 0]
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              }
            },
            axisLabel: {
              fontSize: 16 * px,
              formatter: yAxis[0].formatter,
              overflow: 'breakAll',
            },
            splitLine: {
              show: false
            }
          },
          {
            type: 'value',
            name: yAxis[1].name,
            nameTextStyle: {
              padding: [0, 0, 20, 0]
            },
            position: 'right',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff',
              }
            },
            axisLabel: {
              fontSize: 16 * px,
              formatter: yAxis[1].formatter,
              overflow: 'breakAll',
            },
            splitLine: {
              show: false
            }
          }
        ],
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: data.label,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',
              fontSize: 16 * px
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1 * px
            }
          }
        },
        color: color,
        series: data.data,
        textStyle: {
          fontSize: 16 * px
        },
        ...option
      }
    },
    async init () {
      let data = {}
      if (this.$route.query.demo !== 'Y') {
        this.userInfo = JSON.parse(this.$root.libs.data.getStorage('userInfo'))
        data = {
          project: await this.getProject(),
          online: await this.getOnLine(),
          sex: await this.getAgeOrSexData('sex'),
          age: await this.getAgeOrSexData('age'),
        }
      } else data = json.trendsData.data
      this.echartDataProject = data.project
      if (this.echartDataProject.label.length > 0) {
        this.echartDataProject.data.forEach(item => {
          item.emphasis = {
            focus: 'series'
          }
          switch (item.key) {
            case 'takeTimes':
              if (!item.type) item.type = 'bar'
              if (!item.label)
                item.label = { show: true, position: 'insideBottom', distance: 20, color: '#fff', formatter: '{c}次' }
              item.barMaxWidth = '180'
              break

            case 'keepTime':
              if (!item.type) item.type = 'line'
              item.yAxisIndex = 1
              if (!item.label)
                item.label = { show: true, position: 'right', distance: 30, color: '#fff', formatter: '{c}分钟' }
              break
          }
        })
      }
      this.echartDataOnline = data.online
      if (this.echartDataOnline.label.length > 0) {
        this.echartDataOnline.data.forEach((item, i) => {
          item.emphasis = {
            focus: 'series'
          }
          switch (item.key) {
            case 'peakUse':
              if (!item.type) item.type = 'bar'
              if (!item.label)
                item.label = { show: true, position: 'insideBottom', distance: 20, color: '#fff', formatter: '{c}台' }
              item.barMaxWidth = '180'
              break
            case 'usage':
              if (!item.type) item.type = 'line'
              if (!item.label)
                item.label = { show: true, position: 'right', distance: 20, color: '#fff', formatter: '{c}%' }
              break
          }
          item.yAxisIndex = i
        })
      }
      this.echartDataSex = data.sex
      this.echartDataAge = data.age
    },
    async getProject () {
      let res = await this.$root.libs.request(
        this.$root.libs.api.ssyypt.training_recipe_running_web
          .queryStudioWorkoutUseNumAndDurationInDays, { daysIn: '30', limit: 10 }, { headers: { jwt: this.userInfo.jwt } }
      )
      res = res.data
      let project = {
        label: res.map(item => item.workoutName),
        data: [
          {
            key: 'takeTimes', // 不能改
            name: '治疗次数',
            data: res.map(item => item.workoutTotal)
          },
          {
            key: 'keepTime', // 不能改
            name: '总治疗时长',
            data: res.map(item => {
              return (item.workoutDuration / 60).toFixed(0)
            })
          }
        ]
      }
      return project
    },
    async getOnLine () {
      let line = await this.$root.libs.request(
        this.$root.libs.api.ssyypt.training_recipe_running_web
          .queryStartupRateUsagePeak, { daysIn: '5', limit: 10 }, { headers: { jwt: this.userInfo.jwt } }
      )
      line = line.data
      let online = {
        label: line.map(item => item.dayLabel),
        data: [
          {
            key: 'peakUse', // 不能改
            name: '近5天设备使用高峰',
            data: line.map(item => item.bleDeviceNumByDay)
          },
          {
            key: 'usage', // 不能改
            name: '近5天设备开机使用率',
            data: line.map(item => {
              return parseFloat(item.bleUsageRateByDay.toFixed(3) * 100)
            })
          }
        ]
      }
      return online
    },
    async getAgeOrSexData (type) {
      let res = await this.$root.libs.request(
        this.$root.libs.api.ssyypt.training_recipe_running_web
          .queryStudioTrainUserSexAgeRatio, { daysIn: '30', ranges: '[1..17] - [18..34] - [35..54] - [55..120]' }, { headers: { jwt: this.userInfo.jwt } }
      )

      if (type === 'age') return await this.ageDataFormat(res.data.userAgeList)
      else if (type === 'sex') {
        let unknow = res.data.userSexList.filter(mItem => mItem.sex === '0' || !mItem.sex)
        if (unknow.length > 0) {
          let total = 0
          unknow.forEach(item => total += item.peopleTotal)
          unknow = { sex: '0', peopleTotal: total }
        } else unknow = {}

        let male = res.data.userSexList.find(item => item.sex === '1') || {}
        let female = res.data.userSexList.find(item => item.sex === '2') || {}
        let sexList = [male, female, unknow], sex = []
        sexList.forEach(item => {
          if (item.sex) {
            let name = ''
            switch (item.sex) {
              case '1':
                name = '男性'
                break
              case '2':
                name = '女性'
                break
              case '0':
                name = '未知'
                break
            }
            sex.push({ name, value: item.peopleTotal || 0 })
          }
        })
        // let sex = [
        //   { name: '女性', value: female.peopleTotal || 0 },
        //   { name: '男性', value: male.peopleTotal || 0 },
        //   { name: '未知', value: unknow.peopleTotal || 0 }
        // ]
        console.log(sex)
        return sex
      }
    },
    async ageDataFormat (list) {
      let age = list.map(item => {
        // item.ageRange = item.ageRange.split('_')[1]
        let min, max
        min = item.ageRange.split('..')[0].replace('[', '')
        max = item.ageRange.split('..')[1].replace(']', '')
        if (max === '0') item.name = '未知'
        else item.name = min + '-' + max + '岁'
        item.value = item.peopleTotal
        return item
      })
      return age
    }
  }
}
</script>
<style lang="scss" scoped>
.trends {
  width: 100%;
  display: flex;
  flex-direction: column;
  .project {
    flex: 1;
    margin-bottom: 2rem;
  }
  .column {
    flex: 1;
    display: flex;
    justify-content: space-between;
    .wrap_box {
      width: calc(50% - 1rem);
      &.sexAndAge {
        display: flex;
        .sex,
        .age {
          flex: 1;
        }
        .sex {
          // min-width: 54%;
        }
      }
    }
  }
}
</style>
